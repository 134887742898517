import {
  NDropdown,
  NButton,
  NIcon,
  NInput,
  NSelect,
  SelectOption,
  NForm,
  NFormItem,
  FormRules,
} from "naive-ui";
import { ref, h, reactive } from "vue";
import { MdMore } from "@vicons/ionicons4";
import { i18n } from "@/i18n";
import { ConversationSchema, LimitSchema } from "@/types/schema";
import { Dialog, Message } from "@/utils/tips";
import { forgetPasswordApi, ForgetPassword, resetPasswordApi } from '@/api/user';

const t = i18n.global.t as any;

const dropdownRenderer = (
  conversation: ConversationSchema,
  handleDeleteConversation: (conversation_id?: string) => void,
  handleChangeConversationTitle: (conversation_id?: string) => void
) =>
  h(
    NDropdown,
    {
      trigger: "hover",
      options: [
        {
          label: t("commons.delete"),
          key: "delete",
          props: {
            onClick: () =>
              handleDeleteConversation(conversation.conversation_id),
          },
        },
        {
          label: t("commons.rename"),
          key: "rename",
          props: {
            onClick: () =>
              handleChangeConversationTitle(conversation.conversation_id),
          },
        },
      ],
    },
    {
      default: () =>
        h(
          NButton,
          {
            size: "small",
            quaternary: true,
            circle: true,
          },
          { default: () => h(NIcon, null, { default: () => h(MdMore) }) }
        ),
    }
  );

  const InputDialog = (
    title: string,
    placeholder: string,
    callback: (inp: string) => Promise<any>,
    success: () => void,
    fail: () => void
  ) => {
    let input = "";
    let secondInput: string | undefined = undefined;
    const d = Dialog.info({
      title: title,
      positiveText: t("commons.confirm"),
      negativeText: t("commons.cancel"),
      content: () =>
        h(NInput, {
          onInput: (e) => (input = e),
          autofocus: true,
          placeholder: placeholder,
        }),
      onPositiveClick() {
        d.loading = true;
        return new Promise((resolve) => {
          callback(input)
            .then(() => {
              success();
              resolve(true);
            })
            .catch(() => {
              fail();
              resolve(true);
            })
            .finally(() => {
              d.loading = false;
            });
        });
      },
    });
  };

const popupInputDialog = (
  title: string,
  placeholder: string,
  callback: (inp: string, inp2:string) => Promise<any>,
  success: () => void,
  fail: () => void
) => {
  const formValue = reactive({
    oldpassword: '',
    newpassword: ''
  });
  const d = Dialog.info({
    title: title,
    positiveText: t("commons.confirm"),
    negativeText: t("commons.cancel"),
    content: () => [
      h(NForm, { model: formValue ,rules: frogetPasswordRules, style: { marginTop: '20px' }, showFeedback: false, labelPlacement: 'left' }, [
        h(NFormItem, { path: "oldpassword", label: t('commons.oldpassword'), style: { marginTop: '20px' }},
          h(NInput, {
            onInput: (e) => (formValue.oldpassword = e),
            placeholder: t("tips.oldPassword"),
            type: "password",
            showPasswordOn: 'click'
          })
        ),
        h(NFormItem, { path: "password", label: t('commons.newpassword'), style: { marginTop: '20px' }},
          h(NInput, {
            onInput: (e) => (formValue.newpassword = e),
            placeholder: t("tips.pleaseEnterPassword"),
            type: "password",
            showPasswordOn: 'click'
          }),
        )
      ])
    ],
    onPositiveClick() {
      d.loading = true;
      return new Promise((resolve) => {
        callback(formValue.oldpassword, formValue.newpassword)
          .then(() => {
            success();
            resolve(true);
          })
          .catch(() => {
            fail();
            resolve(true);
          })
          .finally(() => {
            d.loading = false;
          });
      });
    },
  });
};

const resetInputDialog = (
  title: string,
  placeholder: string,
  callback: (inp: string) => Promise<any>,
  success: () => void
) => {
  const buttonDisabled = ref(false);
  const repasswordDisabled = ref(true);
  const token = generateToken()
  const formValue = reactive({
    email: '',
    requestcode: "",
    password: '',
    repassword: ''
  });

  const sendcode = async () => {
    if (formValue.email === "") {
      Message.error(t("tips.pleaseEnterEmail"))
      return
    };
    if (/@genomics.cn|@bgi.com|@mgi-tech.com/.test(formValue.email) == false) {
      Message.warning(t('tips.wrongEmail'));
      return
    }
    if (await callback(formValue.email) === true) {
      buttonDisabled.value = true;
    }
  }


  const d = Dialog.info({
    title: title,
    positiveText: t("commons.verify"),
    content: () => [
      h(NForm, { model: formValue ,rules: frogetPasswordRules, style: { marginTop: '20px' }, }, [
        h(NFormItem, { path: "email", label: t('commons.email') },
          h(NInput, {
            onInput: (e) => (formValue.email = e),
            placeholder: placeholder,
          })
        ),
        h(NFormItem, { path: "requestcode", label: t('commons.requestCode') }, [
          h(NInput, {
            onInput: (e) => (formValue.requestcode = e),
            placeholder: t("tips.enterRequestCode"),
          }),
          h(NButton, { style: { marginLeft: '15px' }, disabled: buttonDisabled.value, onClick: sendcode },
            t("commons.send"))
        ])
      ])
    ],
    onPositiveClick: async () => {
      d.loading = true;
      const codeForm = {
        token: token.value,
        email: formValue.email,
        requestcode: formValue.requestcode
      };
      try {
        if (formValue.requestcode === "") {
          Message.error(t("tips.enterRequestCode"))
          throw new Error();
        };
        await forgetPasswordApi(codeForm as ForgetPassword).then(res => {
          if (res.status === 200) {
            const d2 = Dialog.info({
              title: t("commons.inputPassword"),
              positiveText: t("commons.send"),
              content: () => [
                h(NForm, { model: formValue, rules: frogetPasswordRules, style: { marginTop: '20px' } }, [
                  h(NFormItem, { path: "password", label: t("tips.pleaseEnterPassword") },
                    h(NInput, {
                      onInput: (e) => {
                        formValue.password = e;
                        repasswordDisabled.value = false;
                      },
                      type: "password",
                      showPasswordOn:'click',
                      autofocus: true,
                      placeholder: t("tips.pleaseEnterPassword"),
                    }),
                  ),
                  h(NFormItem, { path: "reenteredPassword", label: t("commons.passwordAgain") },
                    h(NInput, {
                      onInput: (e) => {
                        formValue.repassword = e;
                      },
                      type: "password",
                      showPasswordOn:'click',
                      disabled: repasswordDisabled.value,
                      placeholder: t("tips.enterPasswordAgain"),
                    }),
                  )
                ])
              ],
              onPositiveClick: async () => {
                d2.loading = true
                try {
                  if (formValue.password === "") {
                    Message.error(t("tips.pleaseEnterPassword"))
                    throw new Error();
                  }
                  if (formValue.repassword === "") {
                    Message.error(t("tips.enterPasswordAgain"))
                    throw new Error();
                  }
                  if (formValue.password != formValue.repassword) {
                    Message.error(t("tips.passwordException"))
                    throw new Error();
                  }
                  await resetPasswordApi(token.value, formValue.password).then(res2 => {
                    if (res2.status === 200) {
                      Message.info(t("tips.resetUserPasswordSuccess"))
                    }
                  })
                } catch (e) {
                  d2.loading = false;
                  return false;
                }
              }
            })
          }
        })
      } catch (e) {
        d.loading = false;
        return false;
      }
    }
  });
};

const frogetPasswordRules: FormRules = {
  email:
    {
      required: true,
      message: t("tips.pleaseEnterEmail"),
      trigger: 'blur'
    },
  requestcode:
    {
      required: true,
      message: t("tips.enterRequestCode"),
      trigger: 'blur'
    },
  oldpassword:
    {
      required: true,
      message: t("tips.oldPassword"),
      trigger: 'blur'
    },
  password:
    {
      required: true,
      message: t("tips.pleaseEnterPassword"),
      trigger: 'blur'
    },
  reenteredPassword:
    {
      required: true,
      message: t("tips.enterPasswordAgain"),
    }
}

const generateToken = () => {
  const token = ref('');
  const array = new Uint32Array(8);
  crypto.getRandomValues(array);
  token.value = Array.from(array, (val) => val.toString(16)).join('');
  return token
}

const popupNewConversationDialog = (
  callback: (_conv_title: string, _conv_model: string) => Promise<any>
) => {
  let convTitle = "";
  let convModel = "";
  const d = Dialog.info({
    title: t("commons.newConversation"),
    positiveText: t("commons.confirm"),
    negativeText: t("commons.cancel"),
    // content: () =>
    //   h(NInput, { onInput: (e) => (input = e), autofocus: true, placeholder: placeholder, }),

    // 用一个 NInput 和一个 NSelect
    content: () =>
      h(
        "div",
        {
          style: "display: flex; flex-direction: column; gap: 16px;",
        },
        [
          h(NInput, {
            onInput: (e) => (convTitle = e),
            autofocus: true,
            placeholder: t("tips.conversationTitle"),
          }),
          h(NSelect, {
            placeholder: t("tips.whetherUsePaidModel"),
            "onUpdate:value": (value: string) => (convModel = value),
            options: [
              { label: t("commons.shaModel"), value: "sha" },
              { label: t("commons.paidModel"), value: "paid" },
            ] as SelectOption[],
          }),
        ]
      ),
    onPositiveClick() {
      d.loading = true;
      return new Promise((resolve) => {
        callback(convTitle, convModel)
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(true);
          })
          .finally(() => {
            d.loading = false;
          });
      });
    },
  });
};

const popupChangeConversationTitleDialog = (
  conversation_id: string,
  callback: (title: string) => Promise<any>,
  success: () => void,
  fail: () => void | null
) => {
  InputDialog(
    t("commons.rename"),
    t("tips.rename"),
    callback,
    success,
    fail
  );
};

const popupResetUserPasswordDialog = (
  callback: (oldpassword: string, newpassword:string) => Promise<any>,
  success: () => void,
  fail: () => void
) => {
  popupInputDialog(
    t("commons.resetPassword"),
    t("tips.resetPassword"),
    callback,
    success,
    fail
  );
};

const foundUserPasswordDialog = (
  callback: (email: string) => Promise<any>,
  success: () => void
) => {
  resetInputDialog(
    t("commons.foundPassword"),
    t("tips.pleaseEnterEmail"),
    callback,
    success
  );
};

export {
  dropdownRenderer,
  popupNewConversationDialog,
  popupChangeConversationTitleDialog,
  popupResetUserPasswordDialog,
  foundUserPasswordDialog,
};
