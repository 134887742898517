<template>
  <n-card content-style="padding: 0;">
    <n-list hoverable show-divider v-for="item, i of items" :key="i">
      <n-list-item>
        <div class="flex flex-row justify-between content-center">
          <div>{{ item.title }}</div>
          <div>{{ item.value }}</div>
        </div>
      </n-list-item>
    </n-list>
  </n-card>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useUserStore } from '@/store';
import { UserRead } from '@/types/schema';
import { i18n } from '@/i18n';
const t = i18n.global.t as any;

const userStore = useUserStore();
const user: UserRead | null = userStore.user;
const propsToShow = ['username', 'email', 'nickname', 'organization', 'active_time'];

const translateKey = (key: string) => {
  if (['username', 'email'].includes(key)) {
    return key;
  }
  return t(`labels.${key}`);
}

const translateValue = (key: string, value: any) => {
  if (key === 'active_time') {
    return value ? new Date(value).toLocaleString() : t('commons.neverActive');
  } 
  return value;
}

const items = computed(() => {
  if (!user) return [];
  return propsToShow.map(prop => {
    return {
      title: translateKey(prop),
      value: translateValue(prop, user[prop as keyof UserRead])
    }
  })
})

</script>

