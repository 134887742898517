enum ApiUrl {
  Register = "/auth/register",
  Login = "/auth/login",
  Logout = "/auth/logout",
  UserInfo = "/user/me",
  Conversation = "/conv",
  UserList = "/user",
  ServerStatus = "/status",
  SystemInfo = '/system/info',
  SystemRequestStatistics = '/system/request_statistics',
  ProxyLogs = '/system/proxy_logs',
  ServerLogs = '/system/server_logs',
  emailSend = "/sendmail",
  Authorization = "/user",
  UserInfoSingle = "/user_search",
  forgetPassword = "/user/forget-password",
  resetPassword = "/user/reset-password"
}

export default ApiUrl;
