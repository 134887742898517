import { defineStore } from "pinia/dist/pinia";
import { UploadFileInfo } from 'naive-ui';

const useUploadFileStatus = defineStore({
    id: 'selectedFiles',
    persist: {
        key: 'selectedFilesKey',
        storage: sessionStorage,
        paths: ['selectedFiles','translanguage1','translanguage2']
    },
    state: () => ({
        selectedFiles: [] as UploadFileInfo[],
        translanguage1: "English",
        translanguage2: "Chinese",
    }),
    getters: {
        translanguage3(state) {
          return `api/upload/${state.translanguage1}_${state.translanguage2}`;
        }
    }
});

export default useUploadFileStatus;
