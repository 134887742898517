import { createPinia } from "pinia";
import useAppStore from "./modules/app";
import useUserStore from "./modules/user";
import useModeStatus from "./modules/modestatus";
import useUploadFileStatus from './modules/uploadfilestatus';
import useConversationStore from "./modules/conversation";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export { useAppStore, useUserStore, useConversationStore, useModeStatus, useUploadFileStatus };
export default pinia;
