import { createRouter, createWebHistory } from "vue-router";
import { useLoadingBar } from "naive-ui";
import createRouteGuard from "./guard";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "home",
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/home.vue"),
      meta: {
        requiresAuth: false,
        roles: ["superuser", "user"],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/login/index.vue"),
      meta: {
        requiresAuth: false,
        roles: ["superuser", "user"],
      },
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/register/index.vue"),
    },
    {
      path: "/conversation",
      name: "conversation",
      component: () => import("@/views/conversation/index.vue"),
      meta: {
        requiresAuth: true,
        roles: ['superuser', "user"],
      },
    },
    {
      path: '/conv/:conversation_id',
      name: 'conversationHistory',
      component: () => import('@/views/conversation/history-viewer.vue'),
      meta: {
        requiresAuth: true,
        roles: ['superuser', 'user'],
      },
    },
    {
      path: '/admin',
      name: 'admin',
      redirect: '/admin/system',
      component: () => import('@/views/admin/index.vue'),
      meta: {
        requiresAuth: true,
        roles: ['superuser'],
      },
      children: [
        {
          path: 'system',
          name: 'systemManagement',
          component: () => import('@/views/admin/system_manager.vue'),
        },
        {
          path: 'user',
          name: 'userManagement',
          component: () => import('@/views/admin/user_manager.vue'),
        },
        {
          path: 'conversation',
          name: 'conversationManagement',
          component: () => import('@/views/admin/conversation_manager.vue'),
        },
        {
          path: 'log',
          name: 'logViewer',
          component: () => import('@/views/admin/log_viewer.vue'),
        },
      ],
    },
    // {
    //   path: '/profile',
    //   name: 'editProfile',
    //   component: () => import("@/components/EditUserProfile.vue"),
    //   meta: {
    //     requiresAuth: true,
    //     roles: ['user']
    //   }
    // },
    {
      path: "/waitinglist",
      name: "waitinglist",
      component: () => import("@/views/waitinglist/index.vue"),
      meta: {
        requiresAuth: true,
        roles: ["user"],
      },
    },
    {
      path: "/redirect",
      name: "redirectWrapper",
      children: [
        {
          path: "/redirect/:path",
          name: "Redirect",
          component: () => import("@/views/redirect/index.vue"),
          meta: {
            requiresAuth: false,
            roles: ["superuser", "user"],
          },
        },
      ],
    },
    {
      path: "/error",
      name: "errorPageWrapper",
      children: [
        {
          path: "/error/403",
          name: "403",
          component: () => import("@/views/error/403.vue"),
          meta: {
            requiresAuth: false,
            roles: ["superuser", "user"],
          },
        },
      ],
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

createRouteGuard(router);

export default router;
