import { defineStore } from "pinia/dist/pinia";
import { modeType } from '@/types/authorize';

const useModeStatus = defineStore("status", {
  persist: {
      key: 'statusKey',
      storage: sessionStorage,
      paths: ['isConnect','isVersion','isDatabase','isShow']
  },
  state: () => {
    return {
      isVersion: {color:'#c2c2c2', status:false, isDisabled:false},
      isDatabase: {color:'#1772b4', status:true, isDisabled:false},
      isConnect: {color:'#c2c2c2', status:false, isDisabled:false},
      isShow: false
    }
  },
  getters: {  },

  actions: {  },
});

export default useModeStatus;
